.swiper-button-prev {
  width: 40px;
  height: 40px;
  background-color: rgba(68, 147, 182, 0.8);
  color: white;
  /* background-image: url('../Images/chevron_left.svg'); */
  padding: 4px;
  border-radius: 50%;
}
.swiper-button-next {
  width: 40px;
  /* background-image: url('../Images/chevron_right.svg'); */
  height: 40px;
  background-color: rgba(68, 147, 182, 0.8);
  color: white;
  padding: 4px;
  border-radius: 50%;
}
.swiper-button-next::after {
    font-size: 20px;
}
.swiper-button-prev::after {
    font-size: 20px;
}
.swiper-slide-prev {
  opacity: 0.5;
}
.swiper-slide-next {
  opacity: 0.5;
}
