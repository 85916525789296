/* .group-hover:text-white {
  fill: white;
}
.icon {
  fill: black;
  
} */
/* #dashboard {
  fill: black;
} */
.group1:hover #dashboard {
  fill: white;
}

/* 
#users {
  fill: black;
} */
.group2:hover #users {
  fill: white;
}

/* 
#ticket {
  fill: black;
} */
.group4:hover #ticket {
  fill: white;
}

/* 
#setting {
  fill: black;
} */
.group7:hover #setting {
  fill: white;
}

/* 
#notification {
  fill: black;
} */
.group6:hover #notification {
  fill: white;
}

/* 
#application {
  fill: black;
} */
.group5:hover #application {
  fill: white;
}

/* 
#announce {
  fill: black;
} */
.group3:hover #announce {
  fill: white;
}

.group3:hover #demands {
  fill: white;
}